.date-picker {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.helper-text{
    color: #d32f2f;
    font-weight: 500;
    font-size: 12px;
}