.ant-layout {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

section.ant-layout >div {
    border:none;
    margin:none;
}

.MuiDrawer-paper{
    margin-top: 45px;
}
